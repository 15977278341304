<template>
  <v-card :color="ant" flat>
    <v-card-text>
      <v-list-item>
        <v-list-item-content class="">
          <v-list-item-title
            >Customer {{ orderDetail.rating.customer_id }}
            <span class="text-caption font-weight-light">
              {{ formatDateMonth(orderDetail.rating.created_at) }}</span
            >
          </v-list-item-title>
        </v-list-item-content>

        <span> {{ orderDetail.rating.rating }}</span>
        <v-list-item-action class="ml-n1">
          <v-rating
            v-model="orderDetail.rating.rating"
            half-increments
            background-color="orange lighten-3"
            color="orange"
            class=""
            readonly
            size="20"
          ></v-rating>
        </v-list-item-action>
      </v-list-item>
    
      <div class="mt-3">
        <span style="white-space: pre-line" class="ml-4" v-html="orderDetail.rating.review"> </span>
      </div> 
    </v-card-text>
  </v-card>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";
import timeZoneMxn from '@/mixins/timezone_mixin';

export default {
  name: "reviewComponent",
  mixins: [colorMxn, timeZoneMxn],
  props: ["orderDetail"],
  data() {
    return {};
  }
};
</script>
